import { useState, useEffect } from "react";
import { ConfigData } from "../interfaces";
import { defaultConfig } from "../constants";

function useConfigData(): [ConfigData, boolean] {
  const [configData, setConfigData] = useState<ConfigData>(defaultConfig);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data.json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        setConfigData(jsonData);
      } catch (error) {
        console.error("Error loading config", error);
        setConfigData(defaultConfig);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);
  return [configData, isLoading];
}

export default useConfigData;
