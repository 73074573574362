import { ConfigData, Project } from "./interfaces";

export const defaultProject: Project = {
  id: "",
  title: "",
  github: "",
  deployed: "",
  overview: "",
  techStack: "",
  img1: "",
  img2: "",
  description: "",
};

export const defaultConfig: ConfigData = {
  landing: {
    intro:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
    landingPicture: {
      image: null,
      caption: null,
    },
    openToWork: true,
    linkedin: "https://www.linkedin.com/in/ayushman-s-007/",
    email: "singh.ayush8827@gmail.com",
    twitter: "https://twitter.com/AyushmanS007",
    medium: "https://medium.com/@singh.ayush8827",
    github: "https://github.com/Dark0017",
    resume:
      "https://drive.google.com/drive/folders/1kqFit31DF9eQBb7KoMk2VeONWwPWlZRu",
    jobTitle: null,
    jobCompany: null,
    sideProjects: ["something tech related"],
  },
  projects: [],
};
