import "../styles/Header.css";
import { useLocation, useNavigate } from "react-router-dom";
import ImageTransition from "./ImageTransition";
import useLightMode from "../hooks/useLightMode";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLightMode = useLightMode();

  const handleGoBack = () => {
    navigate(-1);
  };

  if (location.pathname === "/") {
    return (
      <div className="header">
        <h1>Hi, I’m Ayushman</h1>
      </div>
    );
  } else {
    return (
      <div className="nav-button-container">
        <div className="nav-back-button" onClick={handleGoBack}>
          <ImageTransition
            lightImage="/left.png"
            darkImage="/left-light.png"
            isLightMode={isLightMode}
            style={{ width: "1em" }}
            alt="back-arrow"
          />
          <span>back</span>
        </div>
        <a href="/">home</a>
      </div>
    );
  }
};
export default Header;
