import "../styles/Footer.css";

import useLightMode from "../hooks/useLightMode";
import ImageTransition from "./ImageTransition";

const Footer = () => {
  const isLightMode = useLightMode();
  const handleContainerClick = () => {
    console.log("woof");
  };
  return (
    <div className="footer-container">
      <div className="dog-bowl-container">
        <span onClick={handleContainerClick} title="yummy food for your dog">
          <ImageTransition
            lightImage="/dog-bowl.png"
            darkImage="/dog-bowl-light.png"
            isLightMode={isLightMode}
            style={{ width: "0.85em" }}
            alt="dog-bowl"
          />
        </span>
      </div>
      <hr style={{ margin: "0 0 1em 0" }} />
    </div>
  );
};

export default Footer;
