const ContactMe = ({
  linkedin,
  email,
  twitter,
}: {
  linkedin: string | null;
  email: string | null;
  twitter: string | null;
}) => {
  return linkedin || email || twitter ? (
    <div className="contact-container">
      You can contact me via{" "}
      {linkedin && (
        <>
          <span className="text-link">
            <a target="_blank" rel="noreferrer" href={linkedin}>
              linkedin
            </a>
          </span>
        </>
      )}
      {email && (
        <>
          {linkedin && ", "}
          <span className="text-link">
            <a href={`mailto:${email}`}>email</a>
          </span>
        </>
      )}
      {twitter && (
        <>
          {(email || linkedin) && " or "}
          <span className="text-link">
            <a target="_blank" rel="noreferrer" href={twitter || ""}>
              twitter
            </a>
          </span>
        </>
      )}
      .
    </div>
  ) : (
    <></>
  );
};

export default ContactMe;
