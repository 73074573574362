const Current = ({
  jobTitle,
  company,
  medium,
  sideProjects,
}: {
  jobTitle: string | null;
  company: string | null;
  medium: string | null;
  sideProjects: string[];
}) => {
  return jobTitle || medium || (sideProjects && sideProjects.length > 0) ? (
    <div className="current-container">
      {jobTitle && (
        <span>
          I am currently working as {jobTitle}{" "}
          {company && <span>at {company}</span>}.
        </span>
      )}
      {(medium || (sideProjects && sideProjects.length > 0)) && (
        <>
          {" On the side "}
          {medium && (
            <>
              I like to{" "}
              <span className="text-link">
                <a target="_blank" rel="noreferrer" href={medium || ""}>
                  write
                </a>
              </span>{" "}
              sometimes
            </>
          )}
          {sideProjects && sideProjects.length > 0 ? (
            <span>
              {medium ? <> and am also </> : <>I'm also </>}
              {sideProjects.map(
                (project, idx) =>
                  project.length > 0 && (
                    <>
                      {idx > 0 && idx < sideProjects.length - 1 && <>, </>}
                      {idx > 0 && idx === sideProjects.length - 1 && <> and </>}
                      <span>{project}</span>
                    </>
                  )
              )}
              .
            </span>
          ) : (
            "."
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Current;
