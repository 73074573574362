import "../styles/Landing.css";
import useConfigData from "../hooks/useConfigData";
import PictureFrame from "../components/PictureFrame";
import ContactMe from "../components/ContactMe";
import Current from "../components/Current";
import Links from "../components/Links";

const Landing = () => {
  const [configData, isLoading] = useConfigData();
  const pageData = configData.landing;
  const projects = configData.projects;

  return (
    <>
      {isLoading ? (
        <>loading...</>
      ) : (
        <div className="landing-container">
          <div>{pageData["intro"]}</div>
          {pageData["landingPicture"] && (
            <PictureFrame
              url={pageData["landingPicture"].image}
              caption={pageData["landingPicture"].caption}
            />
          )}

          {pageData["openToWork"] !== undefined && (
            <div className="work-container">
              <div>Am I open to new work right now?</div>
              {pageData["openToWork"] ? (
                <>
                  <span className="text-link"> Yes</span>
                  <span>, I am </span>
                </>
              ) : (
                <>
                  <span className="text-link">Nope</span>
                  <span>, am busy </span>
                </>
              )}
            </div>
          )}
          <ContactMe
            linkedin={pageData["linkedin"]}
            email={pageData["email"]}
            twitter={pageData["twitter"]}
          />
          <Current
            jobTitle={pageData["jobTitle"]}
            company={pageData["jobCompany"]}
            medium={pageData["medium"]}
            sideProjects={pageData["sideProjects"]}
          />
          <Links
            projects={projects}
            resume={pageData["resume"]}
            github={pageData["github"]}
          />
        </div>
      )}
    </>
  );
};

export default Landing;
