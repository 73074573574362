import "../styles/ProjectList.css";
import { useNavigate } from "react-router-dom";
import useConfigData from "../hooks/useConfigData";

const ProjectList = () => {
  const [configData, isLoading] = useConfigData();

  const projects = configData.projects;
  const navigate = useNavigate();

  return (
    <div className="project-list-container">
      {isLoading ? (
        <>loading...</>
      ) : (
        <>
          {projects.map((item, idx) => {
            return (
              <div className="project-item-container" key={item.id}>
                <div className="project-item-header">
                  <h2>{item.title}</h2>
                  <div className="project-links-container">
                    {item?.github && (
                      <span className="text-link">
                        <a target="_blank" rel="noreferrer" href={item.github}>
                          Github
                        </a>
                      </span>
                    )}
                    {item?.github && item?.deployed && " . "}
                    {item?.deployed && (
                      <span className="text-link">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={item.deployed}
                        >
                          Deployed
                        </a>
                      </span>
                    )}
                  </div>
                </div>
                <div>{item.overview}</div>
                <div className="project-item-button-container">
                  <span
                    onClick={() => navigate(`/project/${item.id}`)}
                    className="text-link"
                  >
                    open
                  </span>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ProjectList;
