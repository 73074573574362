import "./App.css";
import Landing from "./pages/Landing";
import PageContainer from "./components/PageContainer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProjectList from "./pages/ProjectList";
import ProjectDetail from "./pages/ProjectDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PageContainer>
        <Landing />
      </PageContainer>
    ),
  },
  {
    path: "/projects",
    element: (
      <PageContainer>
        <ProjectList />
      </PageContainer>
    ),
  },
  {
    path: "/project/:projectId",
    element: (
      <PageContainer>
        <ProjectDetail />
      </PageContainer>
    ),
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} fallbackElement={<div>loading</div>} />
    </div>
  );
}

export default App;
