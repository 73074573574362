import { useState, useEffect } from "react";

function useLightMode() {
  const [isLightMode, setIsLightMode] = useState(false);

  useEffect(() => {
    const preferenceLightMediaQuery = window.matchMedia(
      "(prefers-color-scheme: light)"
    );

    const handleMediaChange = (event: MediaQueryList) => {
      setIsLightMode(event.matches);
    };

    // Initial check
    handleMediaChange(preferenceLightMediaQuery);

    preferenceLightMediaQuery.addEventListener(
      "change",
      (e) => true && handleMediaChange(preferenceLightMediaQuery)
    );

    return () => {
      preferenceLightMediaQuery.removeEventListener(
        "change",
        (e) => true && handleMediaChange(preferenceLightMediaQuery)
      );
    };
  }, []);

  return isLightMode;
}

export default useLightMode;
