import { useState } from "react";

const PictureFrame = ({
  url,
  caption,
}: {
  url: string | null;
  caption: string | null;
}) => {
  const [showPicture, setshowPicture] = useState(true);
  return (
    <div className="image-container">
      {showPicture && (
        <>
          <img
            src={`${url || ""}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              setshowPicture(false);
            }}
            className="landing-image"
            alt=""
          />
          <span className="image-caption">{caption || ""}</span>
        </>
      )}
    </div>
  );
};

export default PictureFrame;
