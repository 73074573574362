import Footer from "./Footer";
import Header from "./Header";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

const PageContainer = ({ children }: Props) => {
  return (
    <div className="outer-page-container">
      <Header />
      <div className="page-container">{children}</div>
      <Footer />
    </div>
  );
};

export default PageContainer;
