import { Project } from "../interfaces";

const Links = ({
  projects,
  resume,
  github,
}: {
  projects: Project[];
  resume: string | null;
  github: string | null;
}) => {
  return (
    <div className="links-container">
      {projects.length > 0 && <a href="projects">Projects</a>}
      {resume && (
        <>
          {projects.length > 0 && " . "}
          <a target="_blank" rel="noreferrer" href={resume || ""}>
            Resume
          </a>
        </>
      )}
      {github && (
        <>
          {(resume || projects.length > 0) && " . "}
          <a target="_blank" rel="noreferrer" href={github || ""}>
            Github
          </a>
        </>
      )}
    </div>
  );
};

export default Links;
