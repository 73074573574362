import { useParams } from "react-router-dom";
import useConfigData from "../hooks/useConfigData";
import "../styles/ProjectDetail.css";
import { useEffect, useState } from "react";
import { defaultProject } from "../constants";

const ProjectDetail = () => {
  const { projectId } = useParams();

  const [configData, isLoading] = useConfigData();
  const projects = configData.projects;

  const [data, setdata] = useState(defaultProject);

  useEffect(() => {
    const currentProject = projects.filter((val) => {
      return `${val.id}` === projectId;
    });
    if (currentProject.length > 0) {
      setdata(currentProject[0]);
    }
  }, [projects, projectId]);
  return (
    <div className="project-detail-container">
      {isLoading ? (
        <>loading...</>
      ) : (
        <div className="project-detail-content-container">
          <div className="project-detail-header">
            <h2>{data.title}</h2>
            <div className="project-links-container">
              {data.github && (
                <a
                  className="text-link"
                  href={data.github}
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </a>
              )}
              {data.github && data.deployed && <span>{" . "}</span>}
              {data.deployed && (
                <a
                  className="text-link"
                  href={data.deployed}
                  target="_blank"
                  rel="noreferrer"
                >
                  Deployed
                </a>
              )}
            </div>
          </div>
          <div className="techstack-container">{data.techStack}</div>
          <div className="overview-container">{data.overview}</div>
          <div className="screenshot-container">
            {data.img1 && (
              <img src={data.img1 || ""} alt="project-screenshot-1" />
            )}
            {data.img2 && (
              <img src={data.img2 || ""} alt="project-screenshot-2" />
            )}
          </div>
          <div className="description-container">{data.description}</div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetail;
