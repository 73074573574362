import { useState, useEffect } from "react";
import "../styles/ImageTransition.css";
const ImageTransition = ({
  lightImage,
  darkImage,
  isLightMode,
  style,
  alt,
}: {
  lightImage: string;
  darkImage: string;
  isLightMode: boolean;
  style?: React.CSSProperties;
  alt?: string;
}) => {
  const [currentImage, setCurrentImage] = useState(lightImage);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setIsTransitioning(true);
    const timer = setTimeout(() => {
      setCurrentImage(isLightMode ? lightImage : darkImage);
      setIsTransitioning(false);
    }, 300); // This should match transition duration

    return () => clearTimeout(timer);
  }, [isLightMode, lightImage, darkImage]);

  return (
    <img
      style={style}
      src={currentImage}
      alt={`${alt ? alt : "image"}`}
      className={`transition-image ${isTransitioning ? "fade-out" : "fade-in"}`}
    />
  );
};

export default ImageTransition;
